@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1
  }
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Wix Madefor Display", sans-serif; */
  /* font-optical-sizing: auto; */
  /* font-style: normal; */
  /* font-weight: 500; */

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}

iframe {
  border: none;
  height: 100%;
  width: 100%;
}

/* Scroll bar */
::-webkit-scrollbar {
  width: 2px; /* Độ rộng ngang của thanh cuộn */
  height: 2px; /* Độ rộng của dọc thanh cuộn */
  background-color: #ffffff; /* Màu nền của thanh cuộn */
}

/* Tùy chỉnh slider của thanh cuộn */
::-webkit-scrollbar-thumb {
  background-color: #bb9b6a; /* Màu của slider */
  border-radius: 2px; /* Bo góc cho slider */
}
